var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('h1',[_vm._v("Dashboard")]),_c('div',[_c('h2',{staticClass:"mt-5"},[_vm._v("Accessi")]),_c('b-row',{staticClass:"my-4 py-4"},[_c('b-col',{attrs:{"cols":"5"}},[_c('b-card',{attrs:{"title":"Login effettuati"}},[_c('LineGraph',{attrs:{"datasets":_vm.datasets,"labels":_vm.labels}})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-card',{attrs:{"title":"Sessioni attive"}},[_c('BarChart',{attrs:{"datasets":_vm.sessioniAttive,"labels":_vm.labels}})],1)],1),_c('b-col',{staticClass:"flex align-items-center justify-content-center",attrs:{"cols":"2"}},[_c('div',{staticClass:"w-100 h-100"},[_c('IndicatorDisplay2',{attrs:{"indicator":{
              title: 'Media sessioni per Utente',
              value: 4,
              type: 'number',
              unit: 'sessioni',
              decimals: 0,
            }}})],1)])],1),_c('h2',{staticClass:"mt-5"},[_vm._v("Formazione")]),_c('b-row',{staticClass:"my-4 py-4"},[_c('b-col',{staticClass:"flex align-items-center justify-content-center",attrs:{"cols":"2"}},[_c('b-card',{staticClass:"w-100 h-100 flex justify-content-center align-items-center"},[_c('IndicatorDisplay2',{attrs:{"indicator":{
              title: 'Utenti Formazione / Utenti Attivi',
              value: 3,
              tot: 73,
              type: 'perc',
              unit: '%',
              decimals: 2,
            }}})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-card',{attrs:{"title":"Media Capitoli completati per Utente"}},[_c('BarChart',{attrs:{"datasets":_vm.mediaCapitoliCompletatiPerUtente,"labels":_vm.labels}})],1)],1),_c('b-col',{staticClass:"h-100",attrs:{"cols":"5"}},[_c('b-card',{staticClass:"h-100",attrs:{"title":"Risposte corrette / Risposte Totali"}},[_c('CombinedChart',{staticClass:"h-75",attrs:{"datasets":_vm.risposteGiusteSuTotali,"labels":_vm.labels}})],1)],1)],1),_c('h2',{staticClass:"mt-5"},[_vm._v("Task")]),_c('b-row',{staticClass:"my-4 py-4"},[_c('b-col',{staticClass:"flex align-items-center justify-content-center",attrs:{"cols":"2"}},[_c('b-card',{staticClass:"w-100 h-100 flex justify-content-center align-items-center"},[_c('IndicatorDisplay2',{attrs:{"indicator":{
              title: 'Utenti Task / Utenti Attivi',
              value: 73,
              tot: 73,
              type: 'perc',
              unit: '%',
              decimals: 2,
            }}})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-card',{attrs:{"title":"Media Task eseguite per Utente"}},[_c('BarChart',{attrs:{"datasets":_vm.mediaTaskEseguitePerUtente,"labels":_vm.labels}})],1)],1),_c('b-col',{staticClass:"h-100",attrs:{"cols":"5"}},[_c('b-card',{staticClass:"h-100",attrs:{"title":"Task completate / Task Assegnate"}},[_c('CombinedChart',{staticClass:"h-75",attrs:{"datasets":_vm.taskCompletateSuAssegnate,"labels":_vm.labels}})],1)],1)],1),_c('h2',{staticClass:"mt-5"},[_vm._v("KPI")]),_c('b-row',{staticClass:"my-4 py-4"},[_c('b-col',{staticClass:"flex align-items-center justify-content-center",attrs:{"cols":"2"}},[_c('b-card',{staticClass:"w-100 h-100 flex justify-content-center align-items-center"},[_c('IndicatorDisplay2',{attrs:{"indicator":{
              title: 'Utenti KPI / Utenti Attivi',
              value: 53,
              tot: 73,
              type: 'perc',
              unit: '%',
              decimals: 2,
            }}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-card',{attrs:{"title":"Media KPI per Utente"}},[_c('BarChart',{attrs:{"datasets":_vm.mediaKpiPerUtente,"labels":_vm.labels}})],1)],1),_c('b-col',{staticClass:"flex align-items-center justify-content-center",attrs:{"cols":"2"}},[_c('b-card',{staticClass:"w-100 h-100 flex justify-content-center align-items-center"},[_c('IndicatorDisplay2',{attrs:{"indicator":{
              title: 'KPI più completato: [Vendita DLD]',
              value: 63,
              tot: 73,
              type: 'perc',
              unit: '%',
              decimals: 2,
            }}})],1)],1),_c('b-col',{staticClass:"flex align-items-center justify-content-center",attrs:{"cols":"2"}},[_c('b-card',{staticClass:"w-100 h-100 flex justify-content-center align-items-center"},[_c('IndicatorDisplay2',{attrs:{"indicator":{
              title: 'Media totale Completamento KPI',
              value: 53,
              tot: 73,
              type: 'perc',
              unit: '%',
              decimals: 2,
            }}})],1)],1),_c('b-col',{staticClass:"flex align-items-center justify-content-center",attrs:{"cols":"2"}},[_c('b-card',{staticClass:"w-100 h-100 flex justify-content-center align-items-center"},[_c('IndicatorDisplay2',{attrs:{"indicator":{
              title: 'KPI meno completato: [Feedback Cliente]',
              value: 3,
              tot: 73,
              type: 'perc',
              unit: '%',
              decimals: 2,
            }}})],1)],1)],1),_c('h2',{staticClass:"mt-5"},[_vm._v("Social")]),_c('b-row',{staticClass:"my-4 py-4"},[_c('b-col',{staticClass:"flex align-items-center justify-content-center",attrs:{"cols":"2"}},[_c('b-card',{staticClass:"w-100 h-100 flex justify-content-center align-items-center"},[_c('IndicatorDisplay2',{attrs:{"indicator":{
              title: 'Utenti SocialShare / Utenti Attivi',
              value: 30,
              tot: 73,
              type: 'perc',
              unit: '%',
              decimals: 2,
            }}})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-card',{attrs:{"title":"Media Condivisioni per Utente"}},[_c('BarChart',{attrs:{"datasets":_vm.mediaCondivisioniPerUtente,"labels":_vm.labels}})],1)],1),_c('b-col',{staticClass:"h-100",attrs:{"cols":"5"}},[_c('b-card',{staticClass:"h-100",attrs:{"title":"Dettaglio Social"}},[_c('StackedBarChart',{attrs:{"datasets":_vm.socialStacked,"labels":['Facebook', 'Whatsapp', 'SMS', 'Email']}})],1)],1)],1),_c('b-row',{staticClass:"my-4 py-4",staticStyle:{"height":"400px"}},[_c('b-col',{staticClass:"h-100",attrs:{"cols":"12"}},[_c('b-card',{staticClass:"h-100",attrs:{"title":"Media Tasks"}},[_c('CombinedChart',{staticClass:"h-75",attrs:{"datasets":_vm.datasets,"labels":_vm.labels}})],1)],1)],1),_c('b-row',{staticClass:"my-4 py-4",attrs:{"align-v":"stretch"}},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-card',{staticClass:"mb-2",attrs:{"title":"Durate"}},[_c('BubbleChart',{attrs:{"datasets":_vm.bubbleDatasets,"labels":_vm.labels}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-card',{attrs:{"title":"Durate"}},[_c('BarChart',{attrs:{"datasets":_vm.datasets,"labels":_vm.labels}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-card',{attrs:{"title":"Combinazione Accessi/Durate"}},[_c('RadarChart',{attrs:{"datasets":_vm.datasets,"labels":_vm.labels}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }