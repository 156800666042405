<script>
import AxiosService from "@/axiosServices/AxiosService";
import IndicatorDisplay2 from "../components/DashBoard/IndicatorDisplay2.vue";
import LineGraph from "../components/DashBoard/LineGraph.vue";
import BarChart from "../components/DashBoard/BarChart.vue";
import BubbleChart from "../components/DashBoard/BubbleChart.vue";
import RadarChart from "../components/DashBoard/RadarChart";
import CombinedChart from "../components/DashBoard/CombinedChart.vue";
import StackedBarChart from "../components/DashBoard/StackedBarChart.vue";
export default {
  name: "DashboardStats",
  components: {
    IndicatorDisplay2,
    LineGraph,
    BarChart,
    BubbleChart,
    RadarChart,
    CombinedChart,
    StackedBarChart,
  },
  service: null,
  created() {
    this.service = new AxiosService("Dashboard");
  },
  data() {
    return {
      linechartDataset: [
        {
          label: "Andamento RST",
          data: [10, 20, 30, 25, 35, 45],
        },
        {
          label: "Andamento KPA",
          data: [15, 25, 35, 30, 40, 50],
        },
        // Add more datasets as needed
      ],
      bubbleDatasets: [
        {
          label: "Media RST",
          data: [
            { x: 10, y: 20, r: 15 },
            { x: 30, y: -40, r: -25 },
            { x: 50, y: -60, r: 20 },
            { x: -70, y: 80, r: 10 },
            { x: 90, y: -100, r: 5 },
            { x: 110, y: -120, r: 10 },
            { x: -130, y: 140, r: -15 },
            { x: 150, y: 160, r: -20 },
            { x: 170, y: -180, r: -25 },
            { x: -190, y: 200, r: 30 },
            { x: 210, y: 220, r: 35 },
          ],
        },
        {
          label: "KPA %",
          data: [
            { x: 15, y: 25, r: 10 },
            { x: 35, y: 45, r: 30 },
            { x: 55, y: -65, r: 15 },
            { x: 75, y: 85, r: 5 },
            { x: 95, y: 105, r: -10 },
            { x: 115, y: 125, r: 15 },
            { x: 135, y: 145, r: 20 },
            { x: 155, y: -165, r: 25 },
            { x: -175, y: 185, r: 30 },
            { x: -195, y: -205, r: 35 },
            { x: 215, y: 225, r: 40 },
          ],
        },
      ],
      risposteGiusteSuTotali: [
        {
          label: "Risposte Totali",
          data: [
            72,
            65,
            53,
            48,
            74,
            76,
            66,
            85,
            77,
            68,
            89,
            95,
            45,
            60,
            64,
            58,
            83,
            92,
            88,
            79,
            80,
            81,
            56,
            55,
            40,
            70,
            91,
            63,
            57,
            59,
          ],

          tension: 0.1,
        },
        {
          label: "Risposte Corrette",
          data: [
            28,
            48,
            40,
            19,
            26,
            27,
            20,
            65,
            32,
            17,
            48,
            38,
            14,
            21,
            19,
            54,
            31,
            23,
            6,
            76,
            39,
            43,
            12,
            34,
            25,
            50,
            18,
            11,
            9,
            24,
          ],

          tension: 0.5,
        },
      ],
      taskCompletateSuAssegnate: [
        {
          label: "Task Assegnate",
          data: [
            78,
            62,
            90,
            85,
            69,
            68,
            50,
            80,
            55,
            75,
            85,
            65,
            59,
            95,
            74,
            64,
            88,
            76,
            92,
            86,
            75,
            93,
            98,
            82,
            73,
            66,
            94,
            97,
            90,
            83,
          ],

          tension: 0.1,
        },
        {
          label: "Task Completate",
          data: [
            35,
            52,
            45,
            25,
            35,
            30,
            25,
            72,
            40,
            24,
            50,
            42,
            19,
            78,
            25,
            60,
            38,
            28,
            12,
            82,
            48,
            52,
            18,
            40,
            50,
            55,
            25,
            18,
            15,
            30,
          ],

          tension: 0.5,
        },
      ],
      datasets: [
        {
          label: "Accessi",
          data: [
            14,
            32,
            17,
            34,
            45,
            50,
            48,
            31,
            89,
            40,
            38,
            6,
            9,
            90,
            86,
            76,
            18,
            21,
            19,
            54,
            12,
            65,
            43,
            19,
            27,
            24,
            71,
            28,
            48,
            23,
          ],
          fill: false,
          backgroundColor: "#223352",
          borderColor: "#223352",
          hoverOffset: 0.1,
        },
      ],

      sessioniAttive: [
        {
          label: "Sessioni",
          data: [
            28,
            48,
            40,
            19,
            86,
            27,
            90,
            65,
            32,
            17,
            48,
            38,
            14,
            71,
            19,
            54,
            31,
            23,
            6,
            76,
            89,
            43,
            12,
            34,
            45,
            50,
            18,
            21,
            9,
            24,
          ],
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
      ],
      mediaCapitoliCompletatiPerUtente: [
        {
          label: "Capitoli",
          data: [
            6,
            9,
            43,
            19,
            27,
            90,
            86,
            76,
            14,
            12,
            65,
            24,
            34,
            45,
            50,
            71,
            28,
            48,
            23,
            32,
            17,
            48,
            18,
            21,
            31,
            89,
            40,
            38,
            19,
            54,
          ],
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
      ],
      mediaTaskEseguitePerUtente: [
        {
          label: "Task",
          data: [
            28,
            48,
            23,
            6,
            43,
            12,
            34,
            45,
            50,
            9,
            19,
            27,
            90,
            65,
            32,
            17,
            48,
            18,
            21,
            24,
            86,
            76,
            89,
            40,
            38,
            14,
            71,
            19,
            54,
            31,
          ],
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
      ],
      mediaKpiPerUtente: [
        {
          label: "KPI",
          data: [
            6,
            43,
            19,
            90,
            9,
            27,
            86,
            76,
            12,
            34,
            14,
            65,
            23,
            24,
            45,
            71,
            50,
            28,
            32,
            48,
            18,
            21,
            31,
            40,
            89,
            19,
            38,
            48,
            17,
            54,
          ],
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
      ],
      mediaCondivisioniPerUtente: [
        {
          label: "Condivisioni",
          data: [
            14,
            32,
            17,
            34,
            45,
            50,
            48,
            31,
            89,
            40,
            38,
            6,
            9,
            90,
            86,
            76,
            18,
            21,
            19,
            54,
            12,
            65,
            43,
            19,
            27,
            24,
            71,
            28,
            48,
            23,
          ],
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
      ],
      socialStacked: [
        {
          label: "Condivisioni",
          data: [120, 200, 150, 150],
        },
      ],
      labels: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      stats: {
        boxes: [
          {
            title: "Perc Login",
            value: 360,
            tot: 400,
            type: "perc",
            decimals: 2,
          },
          {
            title: "Media accessi",
            value: 4,
            type: "number",
            unit: "accessi",
            decimals: 1,
          },
          {
            title: "Perc Login",
            value: 260,
            tot: 400,
            type: "perc",
            decimals: 4,
          },
          {
            title: "Perc Login",
            value: 160,
            tot: 400,
            type: "perc",
          },
          {
            title: "Media durata sessione",
            value: 6,
            type: "number",
            unit: "minuti",
          },
          {
            title: "Media durata sessione",
            value: 10,
            type: "number",
            unit: "minuti",
          },
        ],
      },
    };
  },
};
</script>

<template>
  <div class="content-wrapper">
    <h1>Dashboard</h1>

    <div>
      <h2 class="mt-5">Accessi</h2>
      <b-row class="my-4 py-4">
        <b-col cols="5">
          <b-card title="Login effettuati">
            <LineGraph :datasets="datasets" :labels="labels" />
          </b-card>
        </b-col>
        <b-col cols="5">
          <b-card title="Sessioni attive">
            <BarChart :datasets="sessioniAttive" :labels="labels" />
          </b-card>
        </b-col>
        <b-col cols="2" class="flex align-items-center justify-content-center">
          <div class="w-100 h-100">
            <IndicatorDisplay2
              :indicator="{
                title: 'Media sessioni per Utente',
                value: 4,
                type: 'number',
                unit: 'sessioni',
                decimals: 0,
              }"
            />
          </div>
        </b-col>
      </b-row>
      <h2 class="mt-5">Formazione</h2>
      <b-row class="my-4 py-4">
        <b-col cols="2" class="flex align-items-center justify-content-center">
          <b-card
            class="w-100 h-100 flex justify-content-center align-items-center"
          >
            <IndicatorDisplay2
              :indicator="{
                title: 'Utenti Formazione / Utenti Attivi',
                value: 3,
                tot: 73,
                type: 'perc',
                unit: '%',
                decimals: 2,
              }"
            />
          </b-card>
        </b-col>
        <b-col cols="5">
          <b-card title="Media Capitoli completati per Utente">
            <BarChart
              :datasets="mediaCapitoliCompletatiPerUtente"
              :labels="labels"
            />
          </b-card>
        </b-col>
        <b-col cols="5" class="h-100">
          <b-card title="Risposte corrette / Risposte Totali" class="h-100">
            <CombinedChart
              :datasets="risposteGiusteSuTotali"
              :labels="labels"
              class="h-75"
            />
          </b-card>
        </b-col>
      </b-row>

      <h2 class="mt-5">Task</h2>
      <b-row class="my-4 py-4">
        <b-col cols="2" class="flex align-items-center justify-content-center">
          <b-card
            class="w-100 h-100 flex justify-content-center align-items-center"
          >
            <IndicatorDisplay2
              :indicator="{
                title: 'Utenti Task / Utenti Attivi',
                value: 73,
                tot: 73,
                type: 'perc',
                unit: '%',
                decimals: 2,
              }"
            />
          </b-card>
        </b-col>
        <b-col cols="5">
          <b-card title="Media Task eseguite per Utente">
            <BarChart :datasets="mediaTaskEseguitePerUtente" :labels="labels" />
          </b-card>
        </b-col>

        <b-col cols="5" class="h-100">
          <b-card title="Task completate / Task Assegnate" class="h-100">
            <CombinedChart
              :datasets="taskCompletateSuAssegnate"
              :labels="labels"
              class="h-75"
            />
          </b-card>
        </b-col>
      </b-row>

      <h2 class="mt-5">KPI</h2>
      <b-row class="my-4 py-4">
        <b-col cols="2" class="flex align-items-center justify-content-center">
          <b-card
            class="w-100 h-100 flex justify-content-center align-items-center"
          >
            <IndicatorDisplay2
              :indicator="{
                title: 'Utenti KPI / Utenti Attivi',
                value: 53,
                tot: 73,
                type: 'perc',
                unit: '%',
                decimals: 2,
              }"
            />
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card title="Media KPI per Utente">
            <BarChart :datasets="mediaKpiPerUtente" :labels="labels" />
          </b-card>
        </b-col>
        <b-col cols="2" class="flex align-items-center justify-content-center">
          <b-card
            class="w-100 h-100 flex justify-content-center align-items-center"
          >
            <IndicatorDisplay2
              :indicator="{
                title: 'KPI più completato: [Vendita DLD]',
                value: 63,
                tot: 73,
                type: 'perc',
                unit: '%',
                decimals: 2,
              }"
            />
          </b-card>
        </b-col>
        <b-col cols="2" class="flex align-items-center justify-content-center">
          <b-card
            class="w-100 h-100 flex justify-content-center align-items-center"
          >
            <IndicatorDisplay2
              :indicator="{
                title: 'Media totale Completamento KPI',
                value: 53,
                tot: 73,
                type: 'perc',
                unit: '%',
                decimals: 2,
              }"
            />
          </b-card>
        </b-col>

        <b-col cols="2" class="flex align-items-center justify-content-center">
          <b-card
            class="w-100 h-100 flex justify-content-center align-items-center"
          >
            <IndicatorDisplay2
              :indicator="{
                title: 'KPI meno completato: [Feedback Cliente]',
                value: 3,
                tot: 73,
                type: 'perc',
                unit: '%',
                decimals: 2,
              }"
            />
          </b-card>
        </b-col>
      </b-row>
      <h2 class="mt-5">Social</h2>
      <b-row class="my-4 py-4">
        <b-col cols="2" class="flex align-items-center justify-content-center">
          <b-card
            class="w-100 h-100 flex justify-content-center align-items-center"
          >
            <IndicatorDisplay2
              :indicator="{
                title: 'Utenti SocialShare / Utenti Attivi',
                value: 30,
                tot: 73,
                type: 'perc',
                unit: '%',
                decimals: 2,
              }"
            />
          </b-card>
        </b-col>
        <b-col cols="5">
          <b-card title="Media Condivisioni per Utente">
            <BarChart :datasets="mediaCondivisioniPerUtente" :labels="labels" />
          </b-card>
        </b-col>
        <b-col cols="5" class="h-100">
          <b-card title="Dettaglio Social" class="h-100">
            <StackedBarChart
              :datasets="socialStacked"
              :labels="['Facebook', 'Whatsapp', 'SMS', 'Email']"
            />
          </b-card>
        </b-col>
      </b-row>

      <b-row class="my-4 py-4" style="height: 400px;">
        <b-col cols="12" class="h-100">
          <b-card title="Media Tasks" class="h-100">
            <CombinedChart :datasets="datasets" :labels="labels" class="h-75" />
          </b-card>
        </b-col>
      </b-row>

      <b-row class="my-4 py-4" align-v="stretch">
        <b-col cols="4">
          <b-card title="Durate" class="mb-2">
            <BubbleChart :datasets="bubbleDatasets" :labels="labels" />
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card title="Durate">
            <BarChart :datasets="datasets" :labels="labels" />
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card title="Combinazione Accessi/Durate">
            <RadarChart :datasets="datasets" :labels="labels" />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
